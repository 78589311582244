import React from 'react';
import Section from '../../../molecules/Section/Section';
import { Content, Heading, Subhead } from '../styles/HelpCenterHeroStyles';
import { customInnerContentStyles } from '../styles/HeroNewStyles';

const HelpCenterHero = ({ children }) => {
  return (
    <Section bgColor="Blue" hasSvgHexes innerStyle={customInnerContentStyles}>
      <Content>
        <Heading>Shopmonkey Help Center</Heading>
        <Subhead>Advice and answers from the Shopmonkey Team</Subhead>
        {children}
      </Content>
    </Section>
  );
};

export default HelpCenterHero;
